.google {
  background-color: #4285F4;
  color: white;
}

.Swapcontainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
